import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h2>Ponuka</h2>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-10.jpg'
              text='NZ - Skladové zásoby'
              label='Ložiská'
              path='/warehouse-stock'
            />
            <CardItem
              src='images/img-15.jpg'
              text='Oficiálny partner značky SKF'
              label='SKF'
              path='/skf'
            />
            <CardItem
              src='images/img-18.jpg'
              text='Už takmer 30 rokov s Nachi'
              label='Nachi'
              path='/nachi'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Portfólio produktov'
              label='Produkty'
              path='/products'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.jpg'
              text='Výroba podľa technickej dokumentácie'
              label='Služby'
              path='/services'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Špeciálne produkty pre rôzne odvetvia'
              label='Špeciálne'
              path='/special'
            />
            <CardItem
              src='images/img-7.jpg'
              text='Kontaktné údaje a poloha'
              label='Kontakt'
              path='/contact-us'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
