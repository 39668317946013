import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function Nachi() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - Nachi'
          description='Nachi, ložisko, ložiská, Malacky, oficiálny partner značky Nachi, certifikovaný predajca Nachi, priemyselné diely'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='nachi'></h1>
      </div>
      <div className='heros__container'>
        <div className='heros__wrapper'>
          <div className='heros__items'>
            <h2>Certifikovaný predajca Nachi</h2>
            <img className='heros__image'
              src='images/img-17.jpg'
              text='Oficiálny partner značky Nachi'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Nachi;