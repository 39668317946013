import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function SKF() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - SKF'
          description='SKF, ložisko, ložiská, Malacky, oficiálny partner značky SKF, certifikovaný predajca SKF, priemyselné diely'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='skf'></h1>
      </div>
      <div className='heros__container'>
        <div className='heros__wrapper'>
          <div className='heros__items'>
            <h2>Certifikovaný predajca SKF</h2>
            <img className='heros__image'
              src='images/img-16.jpg'
              text='Oficiálny partner značky SKF'
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SKF;