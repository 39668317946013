import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import '../HeroSection.css';
import '../Products.css';
import ProductItem from '../ProductItem';

function Products() {
        return (
                <>
                        <div>
                                <SEO
                                        title='PRETTY Trading - Produkty'
                                        description='ložisko, ložiská, Malacky, lineárna technika, hydraulické prvky, prevodovky, reťaze, tesnenia, rozvody stlačeného vzduchu, silonové tyče, mazivá a chémia, antikorózny papier, kolieska, guličky, motory, Malacky, SKF, Nachi'
                                        name='PRETTY Trading - lozisko.com'
                                        type='article' />
                                <h1 className='products'>Produkty</h1>
                        </div>
                        <div className='products__container'>
                                <div className='products__wrapper'>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Ložiská'
                                                        src='images/products/img-products-1.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text='Lineárna technika'
                                                        src='images/products/img-products-2.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Hydraulické prvky'
                                                        src='images/products/img-products-3.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text='Prevodovky'
                                                        src='images/products/img-products-4.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Reťaze'
                                                        src='images/products/img-products-5.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text='Tesnenia'
                                                        src='images/products/img-products-6.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Rozvody stlačeného vzduchu'
                                                        src='images/products/img-products-7.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text='Silonové tyče'
                                                        src='images/products/img-products-8.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Mazivá a chémia'
                                                        src='images/products/img-products-9.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text='Antikorózny papier'
                                                        src='images/products/img-products-10.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text='Rôzne'
                                                        src='images/products/img-products-11.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text=''
                                                        src='images/products/img-products-12.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                </div>
                        </div>
                </>
        );
}

export default Products;