import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import '../WarehouseStockTable.css';
import HeroSection from '../HeroSection';
import '../HeroSection.css';
import '../Products.css';
import ProductItem from '../ProductItem';

function WarehouseStock() {
    return (
        <>
            <div>
                <SEO
                    title='PRETTY Trading - NZ - skladové zásoby'
                    description='sklad, skladové zásoby, ložisko, ložiská, lineárna technika, SKF, Nachi, ZKL, DKF, GPZ, PFI, NKE, SRO, KG, NSK, CX, NIS, INA, NTN, IBU, ZVL, A&S Fersa, NBI, URB, Koyo, KBS, FAG, WBD, HRDZA, MGM, Steyr, EZO, FKG, ZEN, SNR, TNB, IKO, INA, RHP, SLF, TNG, KINEX KLF'
                    name='PRETTY Trading - lozisko.com'
                    type='article' />
                <h1 className='warehouse-stock'>NZ - skladové zásoby</h1>
            </div>
            <div className='heros__container'>
                <div className='heros__wrapper'>
                    <div className='heros__items'>
                        <h2>Skladové zásoby</h2>
                        <table>
                            <tr>
                                <th>Označenie</th>
                                <th>Značka</th>
                                <th>Poznámka</th>
                                <th>Počet (ks)</th>
                            </tr>
                            <tr>
                                <td>1200 JA</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>1201</td>
                                <td></td>
                                <td> </td>
                                <td>51</td>
                            </tr>
                            <tr>
                                <td>1209</td>
                                <td>ZKL / DKF</td>
                                <td> 115ks ZKL (1990), 5ks P6 ZKL (1983), 35ks DKF</td>
                                <td>155</td>
                            </tr>
                            <tr>
                                <td>1210 ETN9</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>1216 K</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>12BC04S3 C4</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>51306</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>1308 KC3</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>1320 K</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>15BC04S18 SSU</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>16004</td>
                                <td>DKF</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>16005</td>
                                <td>GPZ</td>
                                <td> </td>
                                <td>46</td>
                            </tr>
                            <tr>
                                <td>17BC04S11</td>
                                <td></td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>18790/18720</td>
                                <td></td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>203 KRR2</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>2200 2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>2201 2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>2202 2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>2203 2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>19</td>
                            </tr>
                            <tr>
                                <td>2204 2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>2218</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>22206 J</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>22211 M</td>
                                <td>SRO (Schwitzerland)</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>22219 W33J</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>22234 EW33K C3 (MY)</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>22236</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>22311 KJB</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>22312 CW33</td>
                                <td>KG</td>
                                <td> </td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>22315 CW33</td>
                                <td>KG</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>22322 EX W33 K</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>22324 KM</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>22TM06 C3 (2RS)</td>
                                <td>NSK</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>2302 2RS</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>2304</td>
                                <td>CX</td>
                                <td> </td>
                                <td>19</td>
                            </tr>
                            <tr>
                                <td>23048 K.M.W33</td>
                                <td>NIS</td>
                                <td> </td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>2317</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>52204</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>23236 K</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>24122 CAM</td>
                                <td></td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>25BC05S15 EV SP</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>25BCS11</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>29456</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>3004 2RS</td>
                                <td>INA</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>3005 2Z</td>
                                <td>INA</td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>30215 E</td>
                                <td>ZKL (CSSR)</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>30308 A</td>
                                <td>GPZ (USSR 7308 GPZ)</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>30312 A</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>30320 D</td>
                                <td>Nachi (31320 Nachi)</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>30BC07S7N1 C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>30BCS50 2NSL</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>30BG04S13G-2DST2</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>30BG05S5G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>30BG5222-2DSE</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>30BGS1G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>30BGS32G-2DSE</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>30BX04S1-2DST</td>
                                <td></td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>31320 XJ2</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>3200 A-2RS1 TN9/MT33</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>3200 B.2RS.TV</td>
                                <td>Neutr.</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>32005/26 JS (CR 0574)</td>
                                <td>NTN</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>32015</td>
                                <td>KG</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>3202 B.2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>32020</td>
                                <td>KG</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>32024</td>
                                <td>KG</td>
                                <td>5ks STD, 5ks PREMIUM</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>3205 2Z TN</td>
                                <td>IBU</td>
                                <td> </td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>3209</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>32215</td>
                                <td>KG</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>32218</td>
                                <td>KG</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>32219</td>
                                <td>KG</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>32221</td>
                                <td>KG</td>
                                <td> </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>32304 A</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>32312 BJ2/QCL7C</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>32BG05S1-2DST</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>3302 B.2RS.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>19</td>
                            </tr>
                            <tr>
                                <td>3304 2RS TN</td>
                                <td></td>
                                <td> </td>
                                <td>43</td>
                            </tr>
                            <tr>
                                <td>33113</td>
                                <td>A&S Fersa</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>3312</td>
                                <td>ZKL (CSSR)</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>33209 F</td>
                                <td>A&S Fersa</td>
                                <td> </td>
                                <td>241</td>
                            </tr>
                            <tr>
                                <td>33212 F</td>
                                <td>A&S Fersa</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>35BD4820 DDU</td>
                                <td>NSK</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>35BD6224 DDU</td>
                                <td>NSK</td>
                                <td> </td>
                                <td>27</td>
                            </tr>
                            <tr>
                                <td>35BG05S16G-2DLK2</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>35BG05S7G-2DL</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>35BG5220-2DL</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>35BG5222-2DL</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>35BG5222-2DL2</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>35BX5212 S1-2DST2B1</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>35BX5212 S1-2DST2B1</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>35BX5212 S1-DST</td>
                                <td>KBS</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>361208 R (LR208 NPPU)</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>38BG05S6G-2DST2F</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>40BG05S1G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>40BG05S2G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>40BGS11G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>40BGS12G-2DS</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>40BGS39G-2DL</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>4200 B.TVH</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>4205 B.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4206 B.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>4207 B.TVH</td>
                                <td>NBI</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>4305 B.TV</td>
                                <td>NKE</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>43131/43312</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>51124</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>51124 P6</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>51136</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>51215</td>
                                <td>ZKL</td>
                                <td>24ks P5</td>
                                <td>27</td>
                            </tr>
                            <tr>
                                <td>51226 M</td>
                                <td>URB</td>
                                <td> </td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>51226 M</td>
                                <td>GPZ (USSR)</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>51306 P6</td>
                                <td></td>
                                <td> </td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>515828 (712 1446 10)</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>5207 2RS C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>52204</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>52408 ATNGN</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>21</td>
                            </tr>
                            <tr>
                                <td>529/522</td>
                                <td>A&S Fersa</td>
                                <td> </td>
                                <td>41</td>
                            </tr>
                            <tr>
                                <td>60/22</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>60/22 2RS</td>
                                <td>Koyo</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>60/32 C3</td>
                                <td>Koyo</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>6000 TT C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>6001/012 2NSL</td>
                                <td></td>
                                <td> </td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>6002 VVCM NS7</td>
                                <td></td>
                                <td> </td>
                                <td>173</td>
                            </tr>
                            <tr>
                                <td>6003 2NSE C3 M1</td>
                                <td></td>
                                <td> </td>
                                <td>325</td>
                            </tr>
                            <tr>
                                <td>6004 Z TNH C46</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>45</td>
                            </tr>
                            <tr>
                                <td>6009 N</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6009 Z</td>
                                <td>ZKL (CSSR)</td>
                                <td> </td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>6010 2RS</td>
                                <td>KG / KBS</td>
                                <td> </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>6010 2Z</td>
                                <td>KG / KBS</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6010 P6</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>6013 A P66</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>6013 Z</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>6015</td>
                                <td>FAG</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6018</td>
                                <td>ZKL</td>
                                <td>1ks C3</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6019</td>
                                <td></td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>6048 M</td>
                                <td>DKF</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>62/22 2RS</td>
                                <td>Koyo</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>62/32 C3</td>
                                <td>Koyo</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>62/28 C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>62/28 NR C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>62/28-2NSL C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6201 2RS ODD kryt</td>
                                <td></td>
                                <td> </td>
                                <td>160</td>
                            </tr>
                            <tr>
                                <td>6202 2Z</td>
                                <td>SKF</td>
                                <td>BB1</td>
                                <td>216</td>
                            </tr>
                            <tr>
                                <td>6203 2RS C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>159</td>
                            </tr>
                            <tr>
                                <td>6204 NR</td>
                                <td>SKF</td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>6204 Z TNH C26</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td>6204 Z TNH C56</td>
                                <td>ZVL</td>
                                <td> </td>
                                <td>26</td>
                            </tr>
                            <tr>
                                <td>6205 2RS C3</td>
                                <td>WBD</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>6206 2RS C3 d20</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>6206 Z N</td>
                                <td>GPZ</td>
                                <td>150206 K</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>6209</td>
                                <td>KG</td>
                                <td> </td>
                                <td>150</td>
                            </tr>
                            <tr>
                                <td>6209</td>
                                <td>ZKL</td>
                                <td>15ks P6 (1997), 19ks (1997), 6ks UR A</td>
                                <td>40</td>
                            </tr>
                            <tr>
                                <td>6212 C3</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6212 Z</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>42</td>
                            </tr>
                            <tr>
                                <td>6213 Z C3</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6213 P6</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>6214 2Z</td>
                                <td>KG</td>
                                <td> </td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>6215 2RS</td>
                                <td>KG</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>6215</td>
                                <td></td>
                                <td> </td>
                                <td>143</td>
                            </tr>
                            <tr>
                                <td>6217</td>
                                <td>ZVL</td>
                                <td>párované</td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>6217</td>
                                <td>GPZ</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6218 C3</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>6218</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>6220 P64</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>6220 P6</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>62200 2RS C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>62200 2RS</td>
                                <td></td>
                                <td> </td>
                                <td>36</td>
                            </tr>
                            <tr>
                                <td>62201 2RS C3</td>
                                <td>WBD</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>6221 P64</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6226 M</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>623/22.2 2RS C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>62302 2RS</td>
                                <td>KBS</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>62304 2RS C3</td>
                                <td>WBD</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>62304 2RS C3 d20</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>62304 2RS C3 d17</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>36</td>
                            </tr>
                            <tr>
                                <td>624 2Z</td>
                                <td>KG</td>
                                <td> </td>
                                <td>150</td>
                            </tr>
                            <tr>
                                <td>624</td>
                                <td></td>
                                <td> </td>
                                <td>126</td>
                            </tr>
                            <tr>
                                <td>63/22 X</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>63/22 X C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>63/22 X-2NSL C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>63/22 XNR C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>63/28 NR C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>63/28-2NSL C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>63/32 NR C3</td>
                                <td>Nachi</td>
                                <td> </td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>6311</td>
                                <td></td>
                                <td> </td>
                                <td>310</td>
                            </tr>
                            <tr>
                                <td>6312</td>
                                <td></td>
                                <td> </td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>6315 A</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6318 FLT</td>
                                <td>HRDZA</td>
                                <td> </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>6318 P6 FLT</td>
                                <td>HRDZA</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6319 MA</td>
                                <td></td>
                                <td> </td>
                                <td>109</td>
                            </tr>
                            <tr>
                                <td>6324 P6</td>
                                <td>DKF</td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>6404</td>
                                <td>MGM (Hungary)</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>6409</td>
                                <td>GPZ / MGM</td>
                                <td> 1ks GPZ, 3ks MGM</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>6409 N</td>
                                <td>STEYR</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6416 N</td>
                                <td>ZKL</td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>6700 2Z</td>
                                <td></td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>6802 2ZE</td>
                                <td>Nachi / EZO</td>
                                <td> </td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>6806</td>
                                <td></td>
                                <td>červená skrinka</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>6828</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>69/22-2Z</td>
                                <td></td>
                                <td> </td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>6903 2RS C3</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>6904 DW</td>
                                <td>PFI</td>
                                <td> </td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>6909 2RS</td>
                                <td></td>
                                <td> </td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>6909 2Z</td>
                                <td></td>
                                <td> </td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>6910 2RS</td>
                                <td></td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>6910 2Z</td>
                                <td></td>
                                <td> </td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>6912</td>
                                <td></td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>692X 2Z</td>
                                <td></td>
                                <td> </td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>696A</td>
                                <td></td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>696A 2Z</td>
                                <td></td>
                                <td> </td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>696A 2Z P6E</td>
                                <td></td>
                                <td> </td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>7002 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7004 CY P4</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7004 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>7006 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>0,5</td>
                            </tr>
                            <tr>
                                <td>7007 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>7008 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>3,5</td>
                            </tr>
                            <tr>
                                <td>7010 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>7012 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7014 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>0,5</td>
                            </tr>
                            <tr>
                                <td>7016 AT P4</td>
                                <td></td>
                                <td> </td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>712019 NBR-2</td>
                                <td>Nachi / NSK</td>
                                <td>10ks Nachi, 1ks 40BD49T12DDUKCG48S01 NSK</td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>713 6100 20 (KF 184)</td>
                                <td></td>
                                <td> </td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7200 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7200</td>
                                <td></td>
                                <td></td>
                                <td>24</td>
                            </tr>
                            <tr>
                                <td>7201 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>2,5</td>
                            </tr>
                            <tr>
                                <td>7202 CYDU/GL P4</td>
                                <td></td>
                                <td>párované</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7204 CTYN DUL P4</td>
                                <td>NSK</td>
                                <td>párované</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>7203 BETNG</td>
                                <td></td>
                                <td></td>
                                <td>593</td>
                            </tr>
                            <tr>
                                <td>7205 BTN</td>
                                <td></td>
                                <td></td>
                                <td>222</td>
                            </tr>
                            <tr>
                                <td>7210 AATB P5E</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>7210 AA</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>22</td>
                            </tr>
                            <tr>
                                <td>7216 AATB P5 OM</td>
                                <td></td>
                                <td>párované</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7216 AATB P5</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7216 B</td>
                                <td></td>
                                <td></td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>7219 M</td>
                                <td>GPZ</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7304 B</td>
                                <td></td>
                                <td></td>
                                <td>69</td>
                            </tr>
                            <tr>
                                <td>7306 BEP</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>7308 AA</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>7310 B</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>81191 AD</td>
                                <td>FKG</td>
                                <td>Fabia ZN</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>813049/10</td>
                                <td></td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>86649/10</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>9278/9220</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>949100-2790</td>
                                <td>PFI</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>949100-3190</td>
                                <td>PFI</td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>949100-3330</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>949100-3360</td>
                                <td>PFI</td>
                                <td></td>
                                <td>49</td>
                            </tr>
                            <tr>
                                <td>949100-3480</td>
                                <td>PFI</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>949100-3480</td>
                                <td>WBD</td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>949100-3660 (B15-70 D)</td>
                                <td>PFI</td>
                                <td></td>
                                <td>30</td>
                            </tr>
                            <tr>
                                <td>949100-3820</td>
                                <td>WBD</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>AB 40019 S07 (DG 3773 2RKB)</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>AS 3552</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>67</td>
                            </tr>
                            <tr>
                                <td>ASFW 206</td>
                                <td></td>
                                <td></td>
                                <td>42</td>
                            </tr>
                            <tr>
                                <td>B10-27 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>B10-46 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>B10-50 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>B10-50 TT</td>
                                <td>PFI</td>
                                <td></td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <td>B10-51 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>B12-32 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>B12-57 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>19</td>
                            </tr>
                            <tr>
                                <td>B15-69 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>B15-70 D (949100-3660)</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>B15-83 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>B15-86 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>B17-102 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>B17-107 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>B17-116 D</td>
                                <td>Koyo / NSK</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>B17-127 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>B17-47 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>B17-52 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>B17-99 D</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>B17-99 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>B17-99 DG8</td>
                                <td>NSK</td>
                                <td></td>
                                <td>33</td>
                            </tr>
                            <tr>
                                <td>B22-24 C3</td>
                                <td>PFI</td>
                                <td>otvorené</td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>B22-27 C3 (BB1-0078 SKF) 2RS</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>B25-147 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>B25-157 A CG14</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>B8-23 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>B8-23 D</td>
                                <td>WBD</td>
                                <td></td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>B8-74 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>B8-79 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>B8-85 D</td>
                                <td>PFI</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>B8-85 T12DDNCXMC3E</td>
                                <td>NSK</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>B90 28/32x30</td>
                                <td></td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>BB1-3036</td>
                                <td>SKF</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>BB1-3036 6003 DW</td>
                                <td>PFI</td>
                                <td></td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td>BK 1212</td>
                                <td></td>
                                <td></td>
                                <td>69</td>
                            </tr>
                            <tr>
                                <td>EB 0124</td>
                                <td></td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>EB 0128</td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>EC 12218 S02.H100</td>
                                <td>SNR</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>EC 12567 H206</td>
                                <td>SNR</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>EDS-H 5x25</td>
                                <td></td>
                                <td></td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>F 15041</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>F 15045</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>F 16056</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>F 16092 (LINNING 33.030</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>F 18020 (B17-126 D)</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>F 19033 (RU 9008 UM)</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>FC 40096 S05</td>
                                <td>SNR</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>G 25,400-40 TPX40</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>205</td>
                            </tr>
                            <tr>
                                <td>G 4,000</td>
                                <td></td>
                                <td></td>
                                <td>15860</td>
                            </tr>
                            <tr>
                                <td>GE 20 ES</td>
                                <td></td>
                                <td></td>
                                <td>281</td>
                            </tr>
                            <tr>
                                <td>GE 25 ES</td>
                                <td></td>
                                <td></td>
                                <td>52</td>
                            </tr>
                            <tr>
                                <td>GE 30 ES</td>
                                <td></td>
                                <td></td>
                                <td>162</td>
                            </tr>
                            <tr>
                                <td>GE 35 ES</td>
                                <td></td>
                                <td></td>
                                <td>291</td>
                            </tr>
                            <tr>
                                <td>GE 40 ES</td>
                                <td></td>
                                <td></td>
                                <td>31</td>
                            </tr>
                            <tr>
                                <td>GE 40 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>GE 45 ES</td>
                                <td></td>
                                <td></td>
                                <td>24</td>
                            </tr>
                            <tr>
                                <td>GE 45 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>GE 50 ES</td>
                                <td></td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>GE 50 KRRB</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>GE 6 C</td>
                                <td></td>
                                <td></td>
                                <td>47</td>
                            </tr>
                            <tr>
                                <td>GE 60 ES</td>
                                <td></td>
                                <td></td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>GE 60 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>38</td>
                            </tr>
                            <tr>
                                <td>GE 70 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>85</td>
                            </tr>
                            <tr>
                                <td>GE 70 KRRB</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>GE 8 E</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>GE 80 ES</td>
                                <td></td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>GEEW 32 ES</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>GEEW 50 ES</td>
                                <td></td>
                                <td></td>
                                <td>38</td>
                            </tr>
                            <tr>
                                <td>GEG 100 ES</td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>GEG 20 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>53</td>
                            </tr>
                            <tr>
                                <td>GEG 50 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>50</td>
                            </tr>
                            <tr>
                                <td>GEG 60 ES-2RS</td>
                                <td></td>
                                <td></td>
                                <td>29</td>
                            </tr>
                            <tr>
                                <td>H 17887/31</td>
                                <td>Nachi</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>H 212</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H 218</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>H 2313</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H 2319</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H 2322</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>H 308</td>
                                <td>FAG</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>H715334/11</td>
                                <td>A&S Fersa</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H-E 320/28 J</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H-E 32005 J</td>
                                <td>Nachi</td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>H-E 32205 J</td>
                                <td>Nachi</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>H-LM 12749/11</td>
                                <td>Nachi</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>HM 12649/10</td>
                                <td>Nachi</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>K 25x30x20</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>434</td>
                            </tr>
                            <tr>
                                <td>KM 13</td>
                                <td></td>
                                <td></td>
                                <td>23</td>
                            </tr>
                            <tr>
                                <td>KM 19</td>
                                <td>(CSSR)</td>
                                <td></td>
                                <td>41</td>
                            </tr>
                            <tr>
                                <td>KM 21</td>
                                <td></td>
                                <td></td>
                                <td>160</td>
                            </tr>
                            <tr>
                                <td>KM 22</td>
                                <td></td>
                                <td></td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>KM 3</td>
                                <td></td>
                                <td></td>
                                <td>99</td>
                            </tr>
                            <tr>
                                <td>KM 4</td>
                                <td></td>
                                <td></td>
                                <td>31</td>
                            </tr>
                            <tr>
                                <td>KT 22x28x16 EG</td>
                                <td></td>
                                <td></td>
                                <td>52</td>
                            </tr>
                            <tr>
                                <td>KU 3030</td>
                                <td></td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>KU 40x44x50</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>KZK 16x22x12</td>
                                <td>TNB</td>
                                <td></td>
                                <td>35</td>
                            </tr>
                            <tr>
                                <td>KZK 18x24x12</td>
                                <td>TNB</td>
                                <td></td>
                                <td>28</td>
                            </tr>
                            <tr>
                                <td>KZK 20x26x12</td>
                                <td>TNB</td>
                                <td></td>
                                <td>28</td>
                            </tr>
                            <tr>
                                <td>KZK 20x26x17</td>
                                <td>TNB</td>
                                <td></td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>KZK 22x28x16</td>
                                <td>TNB</td>
                                <td></td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>KZK 25x32x16</td>
                                <td>TNB</td>
                                <td></td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>KZK 30x38x18</td>
                                <td>TNB</td>
                                <td></td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>KZK 35x42x20</td>
                                <td>IKO (KT)</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>KZK 40x48x20</td>
                                <td>IKO (KT)</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>LR 204 NPPU</td>
                                <td>INA</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>LR 204 NPPU</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>LR 205 NPPU</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>LR 206 NPPU</td>
                                <td></td>
                                <td></td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>LR 50/6 2RSR</td>
                                <td></td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>LR 50/8 2RSR</td>
                                <td></td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>LR 5204 KDDU</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>LR 5205 NPPU</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>LR 5206 NPPU GL</td>
                                <td>ZEN</td>
                                <td></td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>LR 604 2RSR</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>LR 606 2RSR</td>
                                <td></td>
                                <td></td>
                                <td>36</td>
                            </tr>
                            <tr>
                                <td>LRT 708030</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>MB 18</td>
                                <td></td>
                                <td></td>
                                <td>14</td>
                            </tr>
                            <tr>
                                <td>MB 20</td>
                                <td></td>
                                <td></td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>MB 3</td>
                                <td></td>
                                <td></td>
                                <td>87</td>
                            </tr>
                            <tr>
                                <td>N 1302-334</td>
                                <td></td>
                                <td></td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>N 1302-387</td>
                                <td></td>
                                <td></td>
                                <td>500</td>
                            </tr>
                            <tr>
                                <td>N 209</td>
                                <td>GPZ (USSR)</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>N 220</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>NATV 40 PP</td>
                                <td></td>
                                <td></td>
                                <td>76</td>
                            </tr>
                            <tr>
                                <td>NB 101</td>
                                <td>PFI</td>
                                <td></td>
                                <td>100</td>
                            </tr>
                            <tr>
                                <td>NB 104</td>
                                <td>PFI</td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>NB 112</td>
                                <td>PFI</td>
                                <td></td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>NIL 6002 AV</td>
                                <td></td>
                                <td></td>
                                <td>184</td>
                            </tr>
                            <tr>
                                <td>NIL 6004 AV</td>
                                <td></td>
                                <td></td>
                                <td>230</td>
                            </tr>
                            <tr>
                                <td>NJ 205 BNA</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>NJ 209</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NJ 214 E</td>
                                <td></td>
                                <td></td>
                                <td>12</td>
                            </tr>
                            <tr>
                                <td>NJ 215</td>
                                <td>GPZ</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 220 M C3</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2210 EAP C4</td>
                                <td>IBC</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2212 EAP</td>
                                <td>IBC</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2214</td>
                                <td></td>
                                <td></td>
                                <td>24</td>
                            </tr>
                            <tr>
                                <td>NJ 222 E.TVP2</td>
                                <td>FAG</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2222 EAP</td>
                                <td>IBC</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 224 C3</td>
                                <td>(CSSR)</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 224 B P6 NA</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>NJ 2308 EMA</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2310</td>
                                <td>ZKL (CSSR)</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NJ 2310</td>
                                <td>DKF</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NK 35/30</td>
                                <td></td>
                                <td></td>
                                <td>40</td>
                            </tr>
                            <tr>
                                <td>NN 3008 KP41</td>
                                <td></td>
                                <td></td>
                                <td>13</td>
                            </tr>
                            <tr>
                                <td>NN 3008 KP51</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NN 3013 KP41</td>
                                <td></td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>NN 3018 KP41</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NN 3024 KP51</td>
                                <td></td>
                                <td></td>
                                <td>18</td>
                            </tr>
                            <tr>
                                <td>NN 3032 KP52</td>
                                <td></td>
                                <td></td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>NNTR 70x180x85 ZZ</td>
                                <td>INA</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NP 15 A</td>
                                <td>RHP</td>
                                <td>len domec</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NU 1008 MA</td>
                                <td>DKF</td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>NU 212</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>NU 216 E</td>
                                <td></td>
                                <td></td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>NU 216 E.TVP3</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 217 E.MA</td>
                                <td>IBC</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>NU 217</td>
                                <td>ZKL (CSSR)</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>NU 218 E.M6</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 219</td>
                                <td>ZKL</td>
                                <td>5ks BNA, 4ks NA</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>NU 22/32</td>
                                <td>CSSR</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 220 E.TVP3</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 220 NA</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NU 2218 EM</td>
                                <td>DKF</td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>NU 2313</td>
                                <td>ZKL (CSSR)</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 306</td>
                                <td>URB</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>NU 311 E.M1</td>
                                <td>SLF</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 311</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NU 314</td>
                                <td></td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>NU 4911</td>
                                <td>TNG</td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>NUB 204 C3</td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>NUB 205 C3</td>
                                <td></td>
                                <td></td>
                                <td>16</td>
                            </tr>
                            <tr>
                                <td>NUB 207 C3</td>
                                <td></td>
                                <td></td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>NUB 210 ETNG C3</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NUB 212 C3</td>
                                <td></td>
                                <td></td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>NUP 2206 E</td>
                                <td></td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>NUP 306 E.M1A</td>
                                <td></td>
                                <td></td>
                                <td>1</td>
                            </tr>
                            <tr>
                                <td>OKLZ 12,700-16</td>
                                <td>KINEX KLF</td>
                                <td>guličky</td>
                                <td>5000</td>
                            </tr>
                            <tr>
                                <td>PLC 03-29-1</td>
                                <td></td>
                                <td></td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>PLC 07-2 TP116</td>
                                <td></td>
                                <td></td>
                                <td>20</td>
                            </tr>
                            <tr>
                                <td>PLC 17-1</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>PLC 23-4</td>
                                <td>ZKL</td>
                                <td></td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td>PLC 64-11</td>
                                <td></td>
                                <td></td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>PW 25520037 CSHD</td>
                                <td>PFI</td>
                                <td></td>
                                <td>11</td>
                            </tr>
                            <tr>
                                <td>PW 25520037 CSHD2</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>PW30600337 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>PW 34640037 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>27</td>
                            </tr>
                            <tr>
                                <td>PW 35650035 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>17</td>
                            </tr>
                            <tr>
                                <td>PW 35680037 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>PW 37720037 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>PW 39/41750037 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>PW 40720037 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>22</td>
                            </tr>
                            <tr>
                                <td>PW 40740040 CS</td>
                                <td>PFI</td>
                                <td></td>
                                <td>150</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WarehouseStock;