import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import '../HeroSection.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function ContactUs() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - Kontakt'
          description='kontakt, ložisko, ložiská, Malacky, SKF, Nachi, sídlo firmy, prevádzka, adresa, otváracie hodiny, otváracia doba, mapa, poloha'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='contact-us'>Kontakt</h1>
      </div>
      <div className='heros__container'>
        <div className='heros__wrapper'>
          <div className='heros__items'>
            <h2>Sídlo firmy:</h2>
            <h3>Cesta mládeže č. 18, 901 01 MALACKY (Slovensko)</h3>
            <p>IČO: 31331700</p>
            <p>IČ DPH: SK 2020350354</p>
          </div>
          <div className='heros__items'>
            <h2>Prevádzka:</h2>
            <h3>Pezinská 18, 901 01 MALACKY (Slovensko)</h3>
            <p>Mobil: +421 903 700 786</p>
            <p>Mobil: +421 903 457 770</p>
            <p>E-mail: office@pretty-trading.sk</p>
            <p>GPS: 48.43252966156699, 17.024348183859523</p>
          </div>
          <div className='heros__items'>
            <h2>Otváracie hodiny:</h2>
            <h3>Pondelok - Štvrtok:</h3>
            <p>9:00 - 12:00 13:00 - 16:00</p>
            <h3>Piatok:</h3>
            <p>9:00 - 12:00</p>
            <h3>Sobota - Nedeľa:</h3>
            <p>Zatvorené</p>
          </div>
          <div className='heros__wrapper'>
            <div className="google-map-code">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.8708215739466!2d17.012572000000002!3d48.43981900000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476cc26b2b8a7a3f%3A0xae8e713da1259b4b!2sPRETTY+Trading%2C+spol.+s+r.o.!5e0!3m2!1scs!2s!4v1398246600554" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;