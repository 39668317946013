import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function Home() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - Úvod'
          description='domov, úvod, o nás, ložisko, ložiská, Malacky, lineárna technika, ponuka, sklad, oficiálny partner značky SKF, Nachi, certifikovaný predajca, priemyselné diely, portfólio, produkty, technická dokumentácia, zákazková výroba, špeciálne produkty, rôzne odvetvia, kontakt, poloha'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='home'>O nás</h1>
      </div>
      <div className='heros__container'>
        <div className='heros__wrapper'>
          <div className='heros__items'>
            <h2>Úvod</h2>
            <p>Naša spoločnosť má stabilné zázemie, ktoré si postupnými krokmi budovala od roku 1992. Počas tejto doby získala mnohé skúsenosti, ktoré jej umožňujú v súčasnosti poskytovať svojim zákazníkom úplné komplexné služby a technické poradenstvo s riešením na mieru a individuálnym prístupom ku každému zákazníkovi.
              To v praxi napr. znamená návrh použitia vhodného náhradného dielu, následného vytvorenia technickej dokumentácie. Dodávame štandardné typizované (katalógové) prvky, ale samozrejme ak je to potrebné, tak aj neštandardné atypické diely. Tieto navrhneme a zrealizujeme od tvorby výkresu až po ich samotnú výrobu. Roky získaných skúseností nás postupne posunuli do pozície, že popri ložiskách a ďalších strojných súčiastkach dodávame našim zákazníkom i diely vyžadujúce náročné certifikáty pre výrobky používané v chemickom a ťažobnom priemysle vyžadujúce osvedčenie o vhodnosti ich použitia vo výbušnom prostredí.</p>
          </div>
        </div>
      </div>
      <Cards />
    </>
  );
}

export default Home;
