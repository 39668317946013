import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>O nás</h2>
            <Link target='_top' to='/'>Úvod</Link>
            <Link target='_top' to='/products'>Produkty</Link>
            <Link target='_top' to='/services'>Služby</Link>
            <Link target='_top' to='/special'>Špeciálne</Link>
            <Link target='_top' to='/contact-us'>Kontakt</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Dokumenty</h2>
            <Link target='_top' to='/terms-conditions'>Všeobecné obchodné podmienky</Link>
            <h2>Sklad</h2>
            <Link target='_top' to='/warehouse-stock'>NZ - skladové zásoby</Link>
            <Link target='_top' to='/PRETTY_Trading-NZ-skladove_zasoby.pdf'>SKLADOVE_ZASOBY.pdf</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Sociálne siete</h2>
            <Link to='/'>Linkedin</Link>
            <Link target='_blank' to='lozisko'>Facebook</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>PRETTY Trading, spol. s r.o. © 2024</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;
