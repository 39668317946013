import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import '../HeroSection.css';
import '../Cards.css';
import Cards from '../Cards';
import '../Products.css';
import ProductItem from '../ProductItem';

function Services() {
        return (
                <>
                        <div>
                                <SEO
                                        title='PRETTY Trading - Služby'
                                        description='ložisko, ložiská, Malacky, SKF, Nachi, služby, zákazková výroba, jednokusová výroba, sériová výroba, dokumentácia'
                                        name='PRETTY Trading - lozisko.com'
                                        type='article' />
                                <h1 className='services'>Služby</h1>
                        </div>
                        <div className='products__container'>
                                <div className='products__wrapper'>
                                        <h2>Zákazková výroba (jednokusové a malé série)</h2>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text=''
                                                        src='images/services/img-services-1.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text=''
                                                        src='images/services/img-services-2.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                        <ul className='products__items'>
                                                <ProductItem
                                                        text=''
                                                        src='images/services/img-services-3.jpg'
                                                        label=''
                                                        path=''
                                                />
                                                <ProductItem
                                                        text=''
                                                        src='images/services/img-services-4.jpg'
                                                        label=''
                                                        path=''
                                                />
                                        </ul>
                                </div>
                        </div>
                </>
        );
}

export default Services;