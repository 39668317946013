import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import '../HeroSection.css';
import '../Cards.css';
import Cards from '../Cards';
import '../Products.css';
import ProductItem from '../ProductItem';

function Special() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - Špeciálne'
          description='ložisko, ložiská, Malacky, SKF, Nachi, špeciálne, výrobky pre petrochemický priemysel, ťažobný priemysel'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='special'>Špeciálne</h1>
      </div>
      <div className='products__container'>
        <div className='products__wrapper'>
          <h2>Výrobky pre petrochemický a ťažobný priemysel</h2>
          <ul className='products__items'>
            <ProductItem
              text='Klapkový ventil'
              src='images/special/img-special-1.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Prevodník tlaku'
              src='images/special/img-special-2.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Pozičný snímač'
              src='images/special/img-special-3.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='TCM aktuátor'
              src='images/special/img-special-4.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Riadiaci modul ohrevu'
              src='images/special/img-special-5.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='PLC modul'
              src='images/special/img-special-6.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='RTD modul'
              src='images/special/img-special-7.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Ukazovateľ prietoku'
              src='images/special/img-special-8.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Prietokový snímač'
              src='images/special/img-special-9.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='Vibračný snímač'
              src='images/special/img-special-10.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Prietokový snímač'
              src='images/special/img-special-11.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Magnetický koncový spínač'
              src='images/special/img-special-12.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='Magnetický ventil'
              src='images/special/img-special-13.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Riadiaci guľový ventil'
              src='images/special/img-special-14.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Špirálový ohrievač'
              src='images/special/img-special-15.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='Prírubová ložisková jednotka'
              src='images/special/img-special-16.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Kónické tesnenie'
              src='images/special/img-special-17.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Regulátor hladiny oleja'
              src='images/special/img-special-18.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='Spojka kompresora'
              src='images/special/img-special-19.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Kompresor'
              src='images/special/img-special-20.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Štartér motora'
              src='images/special/img-special-21.jpg'
              label=''
              path=''
            />
          </ul>
          <ul className='products__items'>
          <ProductItem
              text='Kónický filtračný prvok'
              src='images/special/img-special-22.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Prírubový filtračný prvok'
              src='images/special/img-special-23.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Penový tlmič výfuku'
              src='images/special/img-special-24.jpg'
              label=''
              path=''
            />
          </ul>
          <ul  className='products__items'>
          <ProductItem
              text='Bimetalický teplomer'
              src='images/special/img-special-25.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Horizontálny chladič kvapalín'
              src='images/special/img-special-26.jpg'
              label=''
              path=''
            />
            <ProductItem
              text='Vertikálny chladič kvapalín'
              src='images/special/img-special-27.jpg'
              label=''
              path=''
            />
          </ul>
        </div>
      </div>
    </>
  );
}

export default Special;