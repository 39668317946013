import React from 'react';
import SEO from '../SEO';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';

function TermsConditions() {
  return (
    <>
      <div>
        <SEO
          title='PRETTY Trading - Všeobecné obchodné podmienky'
          description='ložisko, ložiská, Malacky, SKF, Nachi, VOP, Všeobecné obchodné podmienky'
          name='PRETTY Trading - lozisko.com'
          type='article' />
        <h1 className='terms-conditions'>Všeobecné obchodné podmienky</h1>
      </div>
      <div className='heros__container'>
        <div className='heros__wrapper'>
          <div className='heros__items'>
            <h2>VŠEOBECNÉ OBCHODNÉ PODMIENKY PRETTY Trading, spol. s r. o. PRE KÚPU TOVARU</h2>
            <h2>/ znenie platné a účinné od 19.10.2023 /</h2>
            <h3>I. Úvodné ustanovenia</h3>
              <p>1.1. Týmito Všeobecnými obchodnými podmienkami predávajúci  <strong>PRETTY Trading, spol. s r. o.,  Cesta mládeže 18,   Malacky 901 01,</strong>  IČO: 313 317 00   (ďalej len "dodávateľ") a kupujúci určujú časť obsahu kúpnej zmluvy v obchodnom vzťahu v zmysle § 273, ods.1 Obchodného zákonníka.</p>
              <p>1.2. Zmluvou sa rozumie zmluva medzi predávajúcim a kupujúcim, ktorej predmetom je kúpa tovaru v rozsahu predmetu činnosti predávajúceho a v zmysle objednávky kupujúceho.</p>
              <p>1.3. Zodpovednosť za vady tovaru sa riadi ustanoveniami Občianskeho zákonníka.</p>
            <h3>II. Spôsob uzatvorenia zmluvy a odstúpenie</h3>
              <p>2.1. Kupujúci objednáva tovar prostredníctvom e-mailových kontaktov zverejnených na webovej stránke predávajúceho, alebo písomne. Podanou <strong>objednávkou je kupujúci viazaný</strong> a môže ju odvolať len do 48 hodín od jej podania. Odvolanie objednávky po uplynutí lehoty uvedenej v predchádzajúcej vete sa považuje za <strong>odstúpenie od zmluvy, kupujúci v prípade odstúpenia uhradí odstupné vo výške 50 % ceny objednaného tovaru.</strong></p>
              <p>2.2. Zmluva  uzatvorená formou elektronickej alebo písomnej objednávky sa riadi Obchodným zákonníkom, týmito podmienkami, a pokiaľ v nich nie je obsiahnutá odchylná úprava, aj Občianskym zákonníkom. Súčasťou písomne uzavretej Kúpnej zmluvy sú ako nepriame zmluvné dojednania aj tieto Všeobecné obchodné podmienky.</p>
              <p>2.3. V prípade objednania tovaru alebo služieb v e-shope sa zmluva uzatvára tým, že objednávateľ označí vybraný tovar na presun do nákupného košíka, a následne v užívateľskom rozhraní v menu „nákupný košík“ vykoná potvrdenie objednávky a uhradí jej cenu prostredníctvom platobných nástrojov, poskytnutých dodávateľom v užívateľskom rozhraní v menu „pokladňa“.</p>
              <p>2.4. Na spotrebiteľské vzťahy sa neuplatní ustanovenie o odstupnom, ak ide o odstúpenie v zákonnej lehote 14 dní. Spotrebiteľ má právo vrátiť nepoškodený a nepoužitý tovar v zákonnej lehote, pričom má nárok na vrátenie kúpnej ceny tovaru. Do nej sa nezahŕňa poštové a balné, ani poplatok za spracovanie a vyskladnenie.</p>
            <h3>III. Cena a platobné podmienky</h3>
              <p>3.1. Kupujúci je povinný predávajúcemu riadne a včas zaplatiť cenu uvedenú v potvrdenej objednávke, ak niet písomnej zmluvy; ak cena takto nie je dojednaná, strany sa dohodli na určení ceny podľa cenníka predávajúceho; ak ide o tovar zhotovený pre kupujúceho na objednávku, a cenu nemožno takto určiť, <strong>určí cenu tretia osoba, za ktorú strany ustanovujú vybranú osobu podľa čl. VIII  týchto podmienok.</strong></p>
              <p>3.2. Cena je splatná na základe faktúry, ktorú predávajúci vystaví a doručí kupujúcemu na základe preukázateľného prevzatia tovaru.  Splatnosť faktúry, ak sa strany nedohodli inak, je 14 dní od doručenia faktúry v písomnej alebo elektronickej podobe. Na doručovanie elektronickou cestou sa použijú ustanovenia bodu 2.1 týchto podmienok.</p>
              <p>3.3. Cenu hradí kupujúci podľa zvoleného spôsobu úhrady uvedeného v objednávke. V prípade omeškania strany dojednali zmluvný úrok z omeškania vo výške 0,5 % dlžnej sumy za každý deň omeškania, ktorý strany považujú v zmysle judikatúry Najvyššieho súdu SR za súladný so zásadami poctivého obchodného styku a s dobrými mravmi.</p>
              <p>3.4. Súčasťou <strong>dojednania o cene</strong> pri nákupe v e-shope sú samostatne popri cene tovaru uvedeného v e-shope, pri objednávke na doručenie tovaru mimo predajní predávajúceho, aj:</p>
              <p>a) balné a poštovné podľa spôsobu doručovania zvoleného objednávateľom,</p>
              <p>b) skladné vo výške 20 % celkovej ceny bez DPH v prípade, ak dôjde k odmietnutiu prevzatia tovaru zo strany kupujúceho bez odstúpenia od kúpnej zmluvy.</p>
              <p>3.5. Súčasťou <strong>dojednania o cene</strong> pri nákupe v e-shope je samostatne popri cene tovaru uvedeného v e-shope, <strong>pri osobitnej cenovej ponuke (cenové akcie)</strong>, aj poplatok za <strong>vyskladnenie vo výške najviac 20 % celkovej ceny bez DPH;</strong> za súčasť dojednania o cene sa považuje, iba ak pri predložení alebo zverejnení osobitnej cenovej ponuky je poplatok a jeho výška (sadzba) osobitne uvedený v ponuke.</p>
            <h3>IV. Dodanie tovaru a reklamácie</h3>
              <p>4.1. Predávajúci sa zaväzuje dodať objednaný tovar riadne a včas, v objednanom množstve a kvalite, a na miesto určené kupujúcim. Kupujúci je povinný prevzatie tovaru potvrdiť. V prípade, že je tovar odovzdaný na prepravu zasielateľskej spoločnosti, s preukázateľne správnym označením kupujúceho, považuje sa tovar za prevzatý aj bez potvrdenia prevzatia.</p>
              <p>4.2. Reklamácie zjavných vád tovaru, ako aj množstva a druhu tovaru, je kupujúci povinný predložiť pri jeho prevzatí, ak niet odchylného dojednania.</p>
              <p>4.3. Reklamácie tovaru v záručnej dobe kupujúci predloží predávajúcemu písomne alebo elektronicky na dojednanú elektronickú adresu. Po podpísaní preberacieho protokolu v prípade mechanického poškodenia tovaru sa záruka na vady tovaru spôsobené mechanickým poškodením <strong>nevzťahuje</strong>. V prípade mechanického poškodenia podľa tohto odseku berie kupujúci  na vedomie, že mu vznikne nárok na náhradu škody výhradne voči osobám, ktoré poškodenie spôsobili.</p>
              <p>4.4. Ostatné povinnosti zmluvných strán pri nárokoch z vád tovaru sa riadia úpravou zodpovednosti za vady. Kupujúci je však vždy povinný preukázať primerané opatrenia na predchádzanie nesprávneho používania tovaru, alebo jeho používania v rozpore s jeho účelom.</p>
            <h3>V. Zmluvná pokuta za omeškanie</h3>
              <p>5.1. V prípade omeškania kupujúceho s peňažným plnením má predávajúci nárok na zmluvnú pokutu vo výške 1,0 % z dlžnej sumy za každý deň omeškania, ktorá je splatná v deň nasledujúci po doručení výzvy na úhradu, aj v prípade, ak sa výzva vzťahuje len na istinu pohľadávky. Zmluvná pokuta neobmedzuje nárok na náhradu škody. Zmluvnú pokutu vo výške 1 % z dlžnej sumy za každý deň omeškania považujú strany za súladnú s dobrými mravmi v zmysle judikatúry Najvyššieho súdu SR, a primeranú výške zabezpečovaného plnenia.</p>
              <p>5.2. Pre prípad, ak spôsob dojednania zmluvy spôsobuje neplatnosť dojednania o zmluvnej pokute pre nedostatok písomnej formy, sa strany dohodli, že za omeškanie kupujúceho s peňažným plnením má predávajúci nárok na náhradu škody, paušalizovanú vo výške 0.5 % z dlžnej sumy za každý deň omeškania.</p>
            <h3>VI. Zabezpečenie plnenia</h3>
              <p>6.1. Konateľ obchodnej spoločnosti, ktorý podpisuje objednávku tovaru, alebo potvrdzuje prevzatie tovaru, preberá ručenie za záväzok obchodnej spoločnosti ako ručiteľ v zmysle občianskeho zákonníka, a to v rozsahu istiny, úrokov z omeškania, zmluvnej pokuty za omeškania a zmluvnej pokuty podľa ods.5. Ak by ručenie malo byť neplatné pre nedostatok formy, považuje sa podpis konateľa za podpis zakladajúci spoločný záväzok spoločnosti a konateľa, okrem toho, ak v objednávke takéto účinky konateľ pre seba osobitným vyhlásením odmietne.</p>
              <p>6.2. Ak omeškanie dlžníka s plnením presiahne 90 dní, má veriteľ právo požiadať dlžníka o dodatočné zabezpečenie plnenia, najmä zriadením záložného práva, uzatvorením záložnej zmluvy. Veriteľ má právo požiadať dlžníka o uzatvorenie záložnej zmluvy do uplynutia premlčacej doby na dodávku tovaru. V prípade, že dlžník neuzavrie zmluvu, má veriteľ právo požiadať o nahradenie úkonu vôle rozhodcovský súd ustanovený podľa čl. VII týchto podmienok.</p>
              <p>6.3. Ak ktorýkoľvek zo skupiny podnikateľov (§ 2 ObZ), vrátane majetkovo prepojených obchodných spoločností a živnostenských podnikateľov, ktorí majetkovú účasť prejavujú v použití spoločného prvku v obchodnom mene (§ 10, ods. 2 a § 66a Obchodného zákonníka) alebo osôb v postavení ovládanej a ovládajúcej osoby, s obdobným predmetom činnosti ako objednávateľ, objedná tovar od dodávateľa, a následne dôjde:</p>
              <p>a) k jeho prevzatiu iným členom skupiny podnikateľov,</p>
              <p>b) k jeho prevzatiu  v priestoroch prevádzky, miesta podnikania alebo sídla spoločnosti iného člena skupiny,</p>
              <p>c) k jeho prevzatiu v priestoroch  spoločnej prevádzky,</p>
              <p>má sa za to, že členovia takejto skupiny tvoria v čase objednávky a prevzatia tovaru združenie v zmysle § 829 a nasl. Občianskeho zákonníka.</p>
              <p>6.4. Kupujúci a konateľ kupujúceho ako ručiteľ za záväzky kupujúceho sa zaväzujú, že v prípade, ak ktorýkoľvek z nich založí ako spoločník obchodnú spoločnosť s obdobným predmetom činnosti, alebo sa zúčastní jej založenia ako prvý konateľ, alebo mu bude v takej spoločnosti udelená prokúra, na požiadanie predávajúceho ako veriteľa pristúpi táto nová obchodná spoločnosť k záväzku kupujúceho.</p>
              <p>6.5. Kupujúci sa zaväzuje oznámiť predávajúcemu svoju platobnú neschopnosť, ako aj vstup do likvidácie alebo do konkurzu, a to do 10 dní, odkedy rozhodná udalosť nastala; v prípade, že tak neučiní, dodávateľ má právo na zmluvnú pokutu vo výške 0,5 % z dlžnej sumy za každý deň omeškania, splatnú spolu s istinou.</p>
              <p>6.6. Na zmluvné pokuty podľa čl. 6.1.-6.5. sa obdobne vzťahuje čl. 5.2.</p>
            <h3>VII. Riešenie sporov</h3>
              <p>7.1. Všetky spory medzi stranami tejto zmluvy, vzniknuté z tejto zmluvy alebo iných zmlúv medzi stranami, alebo v súvislosti s týmito zmluvami, vrátane sporov o ich platnosť, porušenie, výklad a zrušenie, ako aj spory týkajúce sa arbitrability sporu, budú riešené výlučne ad hoc ustanoveným rozhodcom.</p>
              <p>7.2. Ak nedôjde k odchylnému písomnému dojednaniu alebo spoločnému ustanoveniu rozhodcu, ustanoví na žiadosť ktorejkoľvek strany rozhodcu do desiatich dní vybraná osoba:</p>
              <p>a) E2E- Inštitút pre medzinárodnú obchodnú arbitráž, Zürich,</p>
              <p>b) orgán určený pravidlami konania, na ktoré sa strana odvoláva v oznámení o arbitráži.</p>
              <p>7.3. Strany sa dohodli, že miestom konania bude mesto a kantón Zürich, Švajčiarsko, jazykom konania bude slovenský jazyk a konanie sa bude riadiť pravidlami konania platnými v mieste konania, a to:</p>
              <p>a) Rozhodcovskými pravidlami E2E-Inštitút pre medzinárodnú obchodnú arbitráž (ak žalobca požiada o registráciu konania na E2E-Inštitút pre medzinárodnú obchodnú arbitráž),</p>
              <p>b) Švajčiarskymi pravidlami rozhodcovského konania pri švajčiarskych obchodných komorách (ak žalobca požiada o registráciu rozhodcovský inštitút komôr),</p>
              <p>c) Pravidlami stáleho rozhodcovského súdu zriadeného v kantóne Sankt Gallen (pri ustanovení rozhodcu zapísaného v združení SSG).</p>
              <p>7.4. Zmluvné strany sa výslovne dohodli na použití čl. 192 švajčiarskeho PILA (Gesetz über das internationale Privatrecht) na vylúčenie zrušenia rozhodcovského rozsudku.</p>
            <h3>VIII. Záverečné ustanovenia</h3>
              <p>8.1. Všeobecné obchodné podmienky sú záväzné odo dňa ich zverejnenia na internetovej stránke predávajúceho, preukázateľným zverejnením registrovaného znenia na webstránke E2E-Inštitút pre medzinárodnú obchodnú arbitráž, alebo zverejnením v sídle (prevádzke) predávajúceho, a to dňom prvého zverejnenia. Pre elektronické objednávky z e-shopu sa nepoužije deň prvého zverejnenia v sídle (prevádzke) predávajúceho.</p>
              <p>8.2. Všeobecné obchodné podmienky platia v tom rozsahu a znení, v ktorom sú uvedené na internetovej stránke predávajúceho v deň odoslania objednávky kupujúcim. Tieto podmienky sú založené na slobodnej vôli oboch zmluvných strán, a strany vyhlasujú, že zmeny dispozitívnych ustanovení zákonov, ktorými sa ich zmluvný vzťah riadi, sa ich platnosti netýkajú. Strany považujú obsah týchto podmienok za súladný s dobrými mravmi a v zmysle zásady ´venire contra factum proprium´ vyhlasujú, že v budúcnosti sa nebudú domáhať námietky rozporu týchto podmienok alebo ich časti s dobrými mravmi.</p>
              <p>8.3. Odoslaním objednávky potvrdzuje kupujúci, že akceptuje cenu tovaru, ak je určená, a Všeobecné obchodné podmienky predávajúceho v znení platnom ku dňu odoslania objednávky.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditions;