import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Services from './components/pages/Services';
import Special from './components/pages/Special';
import ContactUs from './components/pages/ContactUs';
import TermsConditions from './components/pages/TermsConditions';
import WarehouseStock from './components/pages/WarehouseStock';
import SKF from './components/pages/SKF';
import Nachi from './components/pages/Nachi';

function App() {
  return (
    <>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/products' element={<Products />} />
            <Route exact path='/services' element={<Services />} />
            <Route exact path='/special' element={<Special />} />
            <Route exact path='/contact-us' element={<ContactUs />} />
            <Route exact path='/terms-conditions' element={<TermsConditions />} />
            <Route exact path='/warehouse-stock' element={<WarehouseStock />} />
            <Route exact path='/skf' element={<SKF />} />
            <Route exact path='/nachi' element={<Nachi />} />
            <Route path="lozisko" element={<Redirect />} />
          </Routes>
          <Footer />
        </Router>
    </>
  );
}

export default App;

function Redirect() {
  window.location.replace('https://www.facebook.com/LOZISKO/');
}
